import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/workspace/src/components/MdxLayout.tsx";
import SEO from 'src/components/SEO';
import MdxCard from 'src/components/MdxCard';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Generator Classes" mdxType="SEO" />
    <MdxCard mdxType="MdxCard">
      <h2 {...{
        "id": "generator-classes"
      }}>{`Generator Classes`}</h2>
      <p><a parentName="p" {...{
          "href": "https://npmjs.org/package/@paljs/generator"
        }}><img parentName="a" {...{
            "src": "https://img.shields.io/npm/v/@paljs/generator.svg",
            "alt": "Version"
          }}></img></a>{`
`}<a parentName="p" {...{
          "href": "https://npmjs.org/package/@paljs/generator"
        }}><img parentName="a" {...{
            "src": "https://img.shields.io/npm/dt/@paljs/generator.svg",
            "alt": "Downloads/total"
          }}></img></a>{`
`}<a parentName="p" {...{
          "href": "https://paljs.com/"
        }}><img parentName="a" {...{
            "src": "https://img.shields.io/npm/l/@paljs/generator.svg",
            "alt": "License"
          }}></img></a></p>
      <p>{`Here is what we use in our `}<a parentName="p" {...{
          "href": "/cli/generator"
        }}>{`CLI`}</a>{` to generate fiels`}</p>
      <p><strong parentName="p">{`CONTENT`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "#backend-generator"
          }}>{`Backend Generator`}</a>
          <ul parentName="li">
            <li parentName="ul"><a parentName="li" {...{
                "href": "#output"
              }}>{`output`}</a></li>
          </ul>
        </li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "#uigenerator"
          }}>{`UIGenerator`}</a>
          <ul parentName="li">
            <li parentName="ul"><a parentName="li" {...{
                "href": "#adminpagesoptions"
              }}>{`Admin Pages Options`}</a></li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "#client-queries-and-mutations"
              }}>{`Client Queries And Mutations`}</a></li>
          </ul>
        </li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "#options-type-definition"
          }}>{`Options`}</a></li>
      </ul>
    </MdxCard>
    <MdxCard mdxType="MdxCard">
      <h2 {...{
        "id": "backend-generator"
      }}>{`Backend Generator`}</h2>
      <p>{`Generate CRUD system for your Prisma GraphQL server`}</p>
      <p><code parentName="p" {...{
          "className": "language-text"
        }}>{`findUnique`}</code>{` | `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`findFirst`}</code>{` | `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`findMany`}</code>{` | `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`findCount`}</code>{` | `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`aggregate`}</code>{` | `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`createOne`}</code>{` | `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`updateOne`}</code>{` | `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`upsertOne`}</code>{` | `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`deleteOne`}</code>{` | `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`updateMany`}</code>{` | `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`deleteMany`}</code></p>
      <br />
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}>{`ARGUMENTS`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Type`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`generator`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`{ name: one of `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`nexus`}</code>{` , `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`sdl`}</code>{` , `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`graphql-modules`}</code>{`, `}<code parentName="td" {...{
                "className": "language-text"
              }}>{`nexus-plugin-prisma`}</code>{`, schemaPath: prisma schema path }`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`options`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`You can add `}<a parentName="td" {...{
                "href": "#options-type-definition"
              }}>{`Options`}</a>{` object to customize the generator`}</td>
          </tr>
        </tbody>
      </table>
      <br />
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "ts"
      }}><pre parentName="div" {...{
          "className": "language-ts"
        }}><code parentName="pre" {...{
            "className": "language-ts"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` Generator `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'@paljs/generator'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`new`}</span>{` `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{`Generator`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` name`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'nexus'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` schemaPath`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'./prisma/schema.prisma'`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`run`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span></code></pre></div>
      <h2 {...{
        "id": "output"
      }}>{`output`}</h2>
      <p>{`Generated code depending on the type of generator`}</p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/generator/nexus#output"
          }}>{`nexus`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/generator/sdl"
          }}>{`sdl`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/generator/graphql-modules"
          }}>{`graphql-modules`}</a></li>
      </ul>
    </MdxCard>
    <MdxCard mdxType="MdxCard">
      <h2 {...{
        "id": "uigenerator"
      }}>{`UIGenerator`}</h2>
      <ul>
        <li parentName="ul">{`admin settings schema uses in our `}<a parentName="li" {...{
            "href": "/prisma-admin/#prisma-table"
          }}>{`PrismaTable`}</a></li>
        <li parentName="ul">{`admin pages for build pages with our Prismatable`}</li>
        <li parentName="ul">{`graphql (queries, mutations) documents to use in your customize code`}</li>
      </ul>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "ts"
      }}><pre parentName="div" {...{
          "className": "language-ts"
        }}><code parentName="pre" {...{
            "className": "language-ts"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` UIGenerator `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'@paljs/generator'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`

`}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// accept one arg for schema.prisma path`}</span>{`
`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` uiGenerator `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`new`}</span>{` `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{`UIGenerator`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token string"
            }}>{`'prisma/schema.prisma'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`

`}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// accept one arg for output of generated settings schema`}</span>{`
uiGenerator`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`buildSettingsSchema`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token string"
            }}>{`'adminSettings.json'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`

`}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// accept \`AdminPagesOptions\` object`}</span>{`
uiGenerator`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`generateAdminPages`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`

`}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// accept \`Options\` Object`}</span>{`
uiGenerator`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`generateGraphql`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`options`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span></code></pre></div>
      <h2 {...{
        "id": "adminpagesoptions"
      }}>{`AdminPagesOptions`}</h2>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "ts"
      }}><pre parentName="div" {...{
          "className": "language-ts"
        }}><code parentName="pre" {...{
            "className": "language-ts"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`interface`}</span>{` `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{`AdminPagesOptions`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// add array of model to just generate pages for them by \`pal g\``}</span>{`
  models`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`?`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token builtin"
            }}>{`string`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// customize page content`}</span>{`
  pageContent`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`?`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token builtin"
            }}>{`string`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// output dir`}</span>{`
  outPut`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`?`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token builtin"
            }}>{`string`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span></code></pre></div>
      <p><strong parentName="p">{`pageContent`}</strong>{` it's your generated page content as string. default:`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "text"
      }}><pre parentName="div" {...{
          "className": "language-text"
        }}><code parentName="pre" {...{
            "className": "language-text"
          }}>{`import React from 'react';
import PrismaTable from 'components/PrismaTable';

const #{id}: React.FC = () => {
  return <PrismaTable model="#{id}" />;
};

export default #{id};`}</code></pre></div>
      <p>{`will replace `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`#{id}`}</code>{` to model name.`}</p>
      <h2 {...{
        "id": "client-queries-and-mutations"
      }}>{`Client Queries And Mutations`}</h2>
      <p>{`Ok now I have tables to manage my db what if I need to add more logic functions and need to build query body.`}</p>
      <p>{`like this`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "graphql"
      }}><pre parentName="div" {...{
          "className": "language-graphql"
        }}><code parentName="pre" {...{
            "className": "language-graphql"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`query`}</span>{` findManyUser`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`
  `}<span parentName="code" {...{
              "className": "token variable"
            }}>{`$where`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` UserWhereInput
  `}<span parentName="code" {...{
              "className": "token variable"
            }}>{`$orderBy`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` UserOrderByInput
  `}<span parentName="code" {...{
              "className": "token variable"
            }}>{`$cursor`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` UserWhereUniqueInput
  `}<span parentName="code" {...{
              "className": "token variable"
            }}>{`$skip`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` Int
  `}<span parentName="code" {...{
              "className": "token variable"
            }}>{`$take`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` Int
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  findManyUser`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token attr-name"
            }}>{`where`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token variable"
            }}>{`$where`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token attr-name"
            }}>{`orderBy`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token variable"
            }}>{`$orderBy`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token attr-name"
            }}>{`cursor`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token variable"
            }}>{`$cursor`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token attr-name"
            }}>{`skip`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token variable"
            }}>{`$skip`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token attr-name"
            }}>{`take`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token variable"
            }}>{`$take`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`...`}</span><span parentName="code" {...{
              "className": "token fragment function"
            }}>{`User`}</span>{`
  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span></code></pre></div>
      <blockquote>
        <p parentName="blockquote">{`NOTE: I create query string in `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`.graphql`}</code>{` files because I use `}<a parentName="p" {...{
            "href": "https://graphql-code-generator.com/docs/plugins/typescript-react-apollo"
          }}><code parentName="a" {...{
              "className": "language-text"
            }}>{`graphql-codegen`}</code></a>{` CLI to auto generate `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`@apollo/client`}</code>{` react hook.`}</p>
      </blockquote>
      <p>{`customize output with adding `}<a parentName="p" {...{
          "href": "#options-type-definition"
        }}>{`Options`}</a>{` to `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`uiGenerator.generateGraphql(options);`}</code></p>
      <p><strong parentName="p">{`Output User model result`}</strong></p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "graphql"
      }}><pre parentName="div" {...{
          "className": "language-graphql"
        }}><code parentName="pre" {...{
            "className": "language-graphql"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`fragment`}</span>{` `}<span parentName="code" {...{
              "className": "token fragment function"
            }}>{`UserFields`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`on`}</span>{` `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{`User`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  id
  email
  name
  password
  createdAt
  groupId
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`fragment`}</span>{` `}<span parentName="code" {...{
              "className": "token fragment function"
            }}>{`User`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`on`}</span>{` `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{`User`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`...`}</span><span parentName="code" {...{
              "className": "token fragment function"
            }}>{`UserFields`}</span>{`
  group `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`...`}</span><span parentName="code" {...{
              "className": "token fragment function"
            }}>{`GroupFields`}</span>{`
  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`query`}</span>{` findUniqueUser`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token variable"
            }}>{`$where`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` UserWhereUniqueInput`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`!`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  findUniqueUser`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token attr-name"
            }}>{`where`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token variable"
            }}>{`$where`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`...`}</span><span parentName="code" {...{
              "className": "token fragment function"
            }}>{`User`}</span>{`
  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`query`}</span>{` findManyUser`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`
  `}<span parentName="code" {...{
              "className": "token variable"
            }}>{`$where`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` UserWhereInput
  `}<span parentName="code" {...{
              "className": "token variable"
            }}>{`$orderBy`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` UserOrderByInput
  `}<span parentName="code" {...{
              "className": "token variable"
            }}>{`$cursor`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` UserWhereUniqueInput
  `}<span parentName="code" {...{
              "className": "token variable"
            }}>{`$skip`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` Int
  `}<span parentName="code" {...{
              "className": "token variable"
            }}>{`$take`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` Int
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  findManyUser`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token attr-name"
            }}>{`where`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token variable"
            }}>{`$where`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token attr-name"
            }}>{`orderBy`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token variable"
            }}>{`$orderBy`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token attr-name"
            }}>{`cursor`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token variable"
            }}>{`$cursor`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token attr-name"
            }}>{`skip`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token variable"
            }}>{`$skip`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token attr-name"
            }}>{`take`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token variable"
            }}>{`$take`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`...`}</span><span parentName="code" {...{
              "className": "token fragment function"
            }}>{`User`}</span>{`
  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`query`}</span>{` findManyUserCount`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`
  `}<span parentName="code" {...{
              "className": "token variable"
            }}>{`$where`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` UserWhereInput
  `}<span parentName="code" {...{
              "className": "token variable"
            }}>{`$orderBy`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` UserOrderByInput
  `}<span parentName="code" {...{
              "className": "token variable"
            }}>{`$cursor`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` UserWhereUniqueInput
  `}<span parentName="code" {...{
              "className": "token variable"
            }}>{`$skip`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` Int
  `}<span parentName="code" {...{
              "className": "token variable"
            }}>{`$take`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` Int
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  findManyUserCount`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token attr-name"
            }}>{`where`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token variable"
            }}>{`$where`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token attr-name"
            }}>{`orderBy`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token variable"
            }}>{`$orderBy`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token attr-name"
            }}>{`cursor`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token variable"
            }}>{`$cursor`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token attr-name"
            }}>{`skip`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token variable"
            }}>{`$skip`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token attr-name"
            }}>{`take`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token variable"
            }}>{`$take`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`mutation`}</span>{` createOneUser`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token variable"
            }}>{`$data`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` UserCreateInput`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`!`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  createOneUser`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token attr-name"
            }}>{`data`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token variable"
            }}>{`$data`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`...`}</span><span parentName="code" {...{
              "className": "token fragment function"
            }}>{`User`}</span>{`
  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`mutation`}</span>{` updateOneUser`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token variable"
            }}>{`$where`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` UserWhereUniqueInput`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`!`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token variable"
            }}>{`$data`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` UserUpdateInput`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`!`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  updateOneUser`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token attr-name"
            }}>{`where`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token variable"
            }}>{`$where`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token attr-name"
            }}>{`data`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token variable"
            }}>{`$data`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`...`}</span><span parentName="code" {...{
              "className": "token fragment function"
            }}>{`User`}</span>{`
  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`mutation`}</span>{` deleteOneUser`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token variable"
            }}>{`$where`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` UserWhereUniqueInput`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`!`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  deleteOneUser`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token attr-name"
            }}>{`where`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token variable"
            }}>{`$where`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    id
  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`mutation`}</span>{` deleteManyUser`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token variable"
            }}>{`$where`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` UserWhereInput`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  deleteManyUser`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token attr-name"
            }}>{`where`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token variable"
            }}>{`$where`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    count
  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`mutation`}</span>{` updateManyUser`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token variable"
            }}>{`$where`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` UserWhereInput`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token variable"
            }}>{`$data`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` UserUpdateManyMutationInput`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`!`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  updateManyUser`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token attr-name"
            }}>{`where`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token variable"
            }}>{`$where`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token attr-name"
            }}>{`data`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token variable"
            }}>{`$data`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    count
  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span></code></pre></div>
    </MdxCard>
    <MdxCard mdxType="MdxCard">
      <h3 {...{
        "id": "options-type-definition"
      }}>{`options type definition`}</h3>
      <div {...{
        "className": "gatsby-highlight has-highlighted-lines",
        "data-language": "ts"
      }}><pre parentName="div" {...{
          "className": "language-ts"
        }}><code parentName="pre" {...{
            "className": "language-ts"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`interface`}</span>{` `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{`Options`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// prisma props name inside the graphql resolvers context. "default: prisma" maybe can be db or you can use with multi schema project to be prisma1, prisma2`}</span>{`
`}<span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}>{`  prismaName`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token builtin"
              }}>{`string`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span></span>{`  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// add array of model to just generate them by \`pal g\``}</span>{`
`}<span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}>{`  models`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`?`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token builtin"
              }}>{`string`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`[`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`]`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span></span>{`  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// models output path`}</span>{`
`}<span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}>{`  output`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token builtin"
              }}>{`string`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span></span>{`  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// generate javaScript files`}</span>{`
`}<span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}>{`  javaScript`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`?`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token builtin"
              }}>{`boolean`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span></span>{`  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// add onDelete.cascade() function on deleteOne and deleteMany mutations default false`}</span>{`
`}<span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}>{`  onDelete`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`?`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token builtin"
              }}>{`boolean`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span></span>{`  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// exclude fields from all models`}</span>{`
`}<span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}>{`  excludeFields`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token builtin"
              }}>{`string`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`[`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`]`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span></span>{`  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// exclude queries or mutations for one or more models`}</span>{`
`}<span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}>{`  excludeModels`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{` name`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token builtin"
              }}>{`string`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{` queries`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`?`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token builtin"
              }}>{`boolean`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span>{` mutations`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`?`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token builtin"
              }}>{`boolean`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`[`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`]`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span></span>{`  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// disable all queries for all models`}</span>{`
`}<span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}>{`  disableQueries`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`?`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token builtin"
              }}>{`boolean`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span></span>{`  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// disable all mutations for all models`}</span>{`
`}<span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}>{`  disableMutations`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`?`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token builtin"
              }}>{`boolean`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span></span>{`  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// exclude fields from one or more models will merge it with general excludeFields`}</span>{`
`}<span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}>{`  excludeFieldsByModel`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`[`}</span>{`modelName`}<span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token builtin"
              }}>{`string`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`]`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token builtin"
              }}>{`string`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`[`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`]`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span></span><span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}>{`  `}<span parentName="span" {...{
                "className": "token comment"
              }}>{`// exclude queries and mutations for one or more model it's object with key : model name value array of QueriesAndMutations type`}</span></span><span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}>{`  `}<span parentName="span" {...{
                "className": "token class-name"
              }}>{`excludeQueriesAndMutationsByModel`}</span><span parentName="span" {...{
                "className": "token operator"
              }}>{`:`}</span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`{`}</span></span>{`    `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span>{`modelName`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token builtin"
            }}>{`string`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` QueriesAndMutations`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "gatsby-highlight-code-line"
            }}>{`  `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`}`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span></span>{`  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// exclude queries and mutations for all models array of QueriesAndMutations type`}</span>{`
  `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{`excludeQueriesAndMutations`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` QueriesAndMutations`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`type`}</span>{` `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{`QueriesAndMutations`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{`
  `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'findUnique'`}</span>{`
  `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'findMany'`}</span>{`
  `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'findCount'`}</span>{`
  `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'aggregate'`}</span>{`
  `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'createOne'`}</span>{`
  `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'updateOne'`}</span>{`
  `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'upsertOne'`}</span>{`
  `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'deleteOne'`}</span>{`
  `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'updateMany'`}</span>{`
  `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'deleteMany'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span></code></pre></div>
    </MdxCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      